import { EXTENDED_DATA_SCHEMA_TYPES } from './types';
import { getFieldValue } from './fieldHelpers';
import { FormattedMessage } from 'react-intl';
import { listingCategory } from '../config/configListing';
import { apiBaseUrl } from './api';
import CryptoJS  from 'crypto-js';
import { BASE_USER_TYPE_MAPPING, profileStatusCompleteField, studentProfileProgressToStageMapping, UserSource } from '../config/configUser';
import appSettings from '../config/settings';


/**
 * Get the namespaced attribute key based on the specified extended data scope and attribute key
 * @param {*} scope extended data scope
 * @param {*} key attribute key in extended data
 * @returns a string containing the namespace prefix and the attribute name
 */
export const addScopePrefix = (scope, key) => {
  const scopeFnMap = {
    private: k => `priv_${k}`,
    protected: k => `prot_${k}`,
    public: k => `pub_${k}`,
    meta: k => `meta_${k}`,
  };

  const validKey = key.replace(/\s/g, '_');
  const keyScoper = scopeFnMap[scope];

  return !!keyScoper ? keyScoper(validKey) : validKey;
};

/**
 * Pick extended data fields from given form data.
 * Picking is based on extended data configuration for the user and target scope and user type.
 *
 * This expects submit data to be namespaced (e.g. 'pub_') and it returns the field without that namespace.
 * This function is used when form submit values are restructured for the actual API endpoint.
 *
 * Note: This returns null for those fields that are managed by configuration, but don't match target user type.
 *       These might exists if user swaps between user types before saving the user.
 *
 * @param {Object} data values to look through against userConfig.js and util/configHelpers.js
 * @param {String} targetScope Check that the scope of extended data the config matches
 * @param {String} targetUserType Check that the extended data is relevant for this user type.
 * @param {Object} userFieldConfigs Extended data configurations for user fields.
 * @returns Array of picked extended data fields from submitted data.
 */
export const pickUserFieldsData = (data, targetScope, targetUserType, userFieldConfigs) => {
  return userFieldConfigs.reduce((fields, field) => {
    const { key, userTypeConfig, scope = 'public', schemaType } = field || {};
    const namespacedKey = addScopePrefix(scope, key);

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetScope = scope === targetScope;
    const isTargetUserType =
      !userTypeConfig.limitToUserTypeIds || userTypeConfig.userTypeIds.includes(targetUserType);

    if (isKnownSchemaType && isTargetScope && isTargetUserType) {
      const fieldValue = getFieldValue(data, namespacedKey);
      return { ...fields, [key]: fieldValue };
    } else if (isKnownSchemaType && isTargetScope && !isTargetUserType) {
      // Note: this clears extra custom fields
      // These might exists if user swaps between user types before saving the user.
      return { ...fields, [key]: null };
    }
    return fields;
  }, {});
};

/**
 * Pick extended data fields from given extended data of the user entity.
 * Picking is based on extended data configuration for the user and target scope and user type.
 *
 * This returns namespaced (e.g. 'pub_') initial values for the form.
 *
 * @param {Object} data extended data values to look through against userConfig.js and util/configHelpers.js
 * @param {String} targetScope Check that the scope of extended data the config matches
 * @param {String} targetUserType Check that the extended data is relevant for this user type.
 * @param {Object} userFieldConfigs Extended data configurations for user fields.
 * @returns Array of picked extended data fields
 */
export const initialValuesForUserFields = (data, targetScope, targetUserType, userFieldConfigs) => {
  return userFieldConfigs.reduce((fields, field) => {
    const { key, userTypeConfig, scope = 'public', schemaType } = field || {};
    const namespacedKey = addScopePrefix(scope, key);

    const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
    const isTargetScope = scope === targetScope;
    const isTargetUserType =
      !userTypeConfig?.limitToUserTypeIds || userTypeConfig?.userTypeIds?.includes(targetUserType);

    if (isKnownSchemaType && isTargetScope && isTargetUserType) {
      const fieldValue = getFieldValue(data, key);
      return { ...fields, [namespacedKey]: fieldValue };
    }
    return fields;
  }, {});
};

/**
 * Returns props for custom user fields
 * @param {*} userFieldsConfig Configuration for user fields
 * @param {*} intl
 * @param {*} userType User type to restrict fields to. If none is passed,
 * only user fields applying to all user types are returned.
 * @param {*} isSignup Optional flag to determine whether the target context
 * is a signup form. Defaults to true.
 * @returns an array of props for CustomExtendedDataField: key, name,
 * fieldConfig, defaultRequiredMessage
 */
export const getPropsForCustomUserFieldInputs = (
  userFieldsConfig,
  intl,
  userType = null,
  isSignup = true
) => {
  return (
    userFieldsConfig?.reduce((pickedFields, fieldConfig) => {
      const { key, userTypeConfig, schemaType, scope, saveConfig = {} } = fieldConfig || {};
      const namespacedKey = addScopePrefix(scope, key);
      const showField = isSignup ? saveConfig.displayInSignUp : true;

      const isKnownSchemaType = EXTENDED_DATA_SCHEMA_TYPES.includes(schemaType);
      const isTargetUserType =
        !userTypeConfig?.limitToUserTypeIds || userTypeConfig?.userTypeIds?.includes(userType);
      const isUserScope = ['public', 'private', 'protected'].includes(scope);

      return isKnownSchemaType && isTargetUserType && isUserScope && showField
        ? [
            ...pickedFields,
            {
              key: namespacedKey,
              name: namespacedKey,
              fieldConfig: fieldConfig,
              defaultRequiredMessage: intl.formatMessage({
                id: 'CustomExtendedDataField.required',
              }),
            },
          ]
        : pickedFields;
    }, []) || []
  );
};

export const prepareContractForSignatureData = (transaction, intl, config) => {
	const service = {
		category: listingCategory[transaction.listing.attributes.publicData.listingType] || {},
		totalFee: Number(transaction?.attributes?.payinTotal?.amount) ? Number(transaction?.attributes?.payinTotal?.amount) / 100 : '',
		esianFee: Number(transaction?.listing?.attributes?.price?.amount) ? Number(transaction?.listing?.attributes?.price?.amount) / 100 : '',
		transactionDate: transaction?.attributes?.createdAt ?? '',
		duration: transaction?.listing?.attributes?.publicData?.Initial_Duration ?? '',
		address: transaction?.listing?.attributes?.publicData?.location?.address ?? '',
	};

	const esian = {		
		mrOrMs: transaction?.provider?.attributes?.profile?.publicData?.Mr_or_Mrs ?? '',
		surname: transaction?.attributes?.protectedData?.providerLastName ?? '',
		name: transaction?.attributes?.protectedData?.providerFirstName ?? '',
		dateOfBirth: transaction?.provider?.attributes?.profile?.publicData?.birthDate ?? '',
		placeOfBirth: transaction?.provider?.attributes?.profile?.publicData?.Place_of_birth?.selectedPlace?.address ?? '',
		fullAddress: transaction?.provider?.attributes?.profile?.publicData?.Address?.selectedPlace?.address ?? '',
		email: transaction?.attributes?.protectedData?.providerEmailAddress ?? '',
		whatsappPhoneNumber: transaction?.customer?.attributes?.profile?.publicData?.whatsappPhoneNumber ?? '',
		phoneNumber: transaction?.customer?.attributes?.profile?.publicData?.phoneNumber ?? '',
	}

	const student = {
		name: `${transaction?.attributes?.protectedData?.customerFirstName ?? ''} ${transaction?.attributes?.protectedData?.customerLastName ?? ''}`,
		mrOrMs: transaction?.customer?.attributes?.profile?.publicData?.Mr_or_Mrs ?? '',
		firstName: transaction?.attributes?.protectedData?.customerFirstName ?? '',
		lastName: transaction?.attributes?.protectedData?.customerLastName ?? '',
		dateOfBirth: transaction?.customer?.attributes?.profile?.publicData?.birthDate ?? '',
		placeOfBirth: transaction?.customer?.attributes?.profile?.publicData?.Place_of_birth?.selectedPlace?.address ?? '',
		fullAddress: transaction?.customer?.attributes?.profile?.publicData?.Address?.selectedPlace?.address ?? '',
		email: transaction?.attributes?.protectedData?.customerEmailAddress ?? '',
		phoneNumber: transaction?.customer?.attributes?.profile?.publicData?.phoneNumber ?? '',
		whatsappPhoneNumber: transaction?.customer?.attributes?.profile?.publicData?.whatsappPhoneNumber ?? '',
	}

	const additionalDetails = {
		esianSignature: intl.formatMessage({id: 'PaymentMethodsPage.esianSignature'}),
		studentSignature: intl.formatMessage({id: 'PaymentMethodsPage.studentSignature'}),
		wihSignature: intl.formatMessage({ id: 'PaymentMethodsPage.wihSignature'}),
		esian_Identification_document: transaction?.attributes?.protectedData?.customerPassportBlobName ?? '',
		esian_home_address_document: transaction?.attributes?.protectedData?.customerAddressJustificationBlobName ?? '',
		wlh_address: config.WLH_address,
		wlh_chief: config.wlh_chief, 
		custom_webhook_url: `${apiBaseUrl()}/api/brevo/contract-status-feedback`,
		admin_email: config.admin_email,
		totalFee: transaction.attributes.payinTotal.amount,
	};

  const category = service?.category?.code || '';
  
	if(category == 'wlh002') {
		return {
			transactionId: transaction.id.uuid,
			template_id: service?.category?.contract_template_id ?? '',
			placeholder_fields: [
				{ api_key: 'Mr_Miss', value: ` ${student.mrOrMs} ` },
				{ api_key: 'Student_First_Name', value: ` ${student.firstName ?? ''} ` },
				{ api_key: 'Student_Last_Name', value: ` ${student.lastName} ` },
				{ api_key: 'Date_of_Birth', value: ` ${student.dateOfBirth} ` },
				{ api_key: 'Place_of_Birth', value: ` ${student.placeOfBirth} ` },
				{ api_key: 'Full_Address', value: ` ${student.fullAddress} ` },
				{ api_key: 'Email_Address', value: ` ${student.email} ` },
				{ api_key: 'WhatsApp_Number', value: ` ${student.whatsappPhoneNumber} ` },
				{ api_key: 'Esian_Mr_Miss', value: ` ${esian.mrOrMs} ` },
				{ api_key: 'esian_surname', value: ` ${esian.surname} ` },
				{ api_key: 'esian_name', value: ` ${esian.name} ` },
				{ api_key: 'Esian_Full_Address', value: ` ${esian.fullAddress} ` },
				{ api_key: 'Esian_Email_Address', value: ` ${esian.email} ` },
				{ api_key: 'esian_birth_location', value: ` ${esian.placeOfBirth} ` },
				{ api_key: 'esian_birth_date', value: ` ${esian.dateOfBirth} ` },
				{ api_key: 'Phone_Number', value: ` ${esian.phoneNumber} ` },
				{ api_key: 'lodging_duration', value: ` ${service.duration} ` },
				{ api_key: 'host_family_town', value: ` ${service.address} ` },
				{ api_key: 'total_fees', value: ` ${service?.totalFee} ` },
				{ api_key: 'esian_fees', value: ` ${service?.esianFee} ` },
				{ api_key: 'transaction_date', value: ` ${service?.transactionDate} ` },
			],
			custom_webhook_url: additionalDetails?.custom_webhook_url ?? '',
			signers: [
				{ name: student.name, email: student.email, signing_order: '2' },
				{ name: esian.name, email: esian.email, signing_order: '1' },
				{ 
					name: additionalDetails?.wlh_chief ?? '',
					auto_sign: 'yes', 
					email: additionalDetails?.admin_email ?? '',
					signing_order: '3'
				}
			]
		};
	}

	if(category == 'wlh004' || category == 'wlh006') {
		return {
			transactionId: transaction.id.uuid,
			template_id: service?.category.contract_template_id,
			placeholder_fields: [
				{ api_key: 'Mr_Miss', value: ` ${student.mrOrMs} ` },
				{ api_key: 'Student_First_Name', value: ` ${student.firstName ?? ''} ` },
				{ api_key: 'Student_Last_Name', value: ` ${student.lastName} ` },
				{ api_key: 'Date_of_Birth', value: ` ${student.dateOfBirth} ` },
				{ api_key: 'Place_of_Birth', value: ` ${student.placeOfBirth} ` },
				{ api_key: 'Full_Address', value: ` ${student.fullAddress} ` },
				{ api_key: 'Email_Address', value: ` ${student.email} ` },
				{ api_key: 'WhatsApp_Number', value: ` ${student.whatsappPhoneNumber} ` },
			],
			custom_webhook_url: additionalDetails?.custom_webhook_url ?? '',
			signers: [
				{ name: student.name, email: student.email, signing_order: '2' },
				{ name: esian.name, email: esian.email, signing_order: '1' }
			]
		};
	}
  return null;
};

/**
 * 
 * @param {*} publicData - User public data.
 * @param {*} protectedData Users private data.
 * @returns Returns true if the user profile is not completed else false.
 */
export const checkUserStatus = (publicData, protectedData) => {
	const userType = publicData?.userType || 'student';
	let validationFields = [];

	switch(userType) {
		case 'student' : validationFields = profileStatusCompleteField.STUDENT; break;
		case 'individualEsian' : validationFields = profileStatusCompleteField.INDIVIDUAL_ESIAN; break;
		case 'organizationEsian' : validationFields = profileStatusCompleteField.ORGANIZATION_ESIAN; break;
		case 'university' : validationFields = profileStatusCompleteField.UNIVERSITY; break;
	}
	return validationFields.some(field => !(publicData?.hasOwnProperty(field) || protectedData?.hasOwnProperty(field)));
};  

export const buildContactCreationDataFromCurrentUser = (currentUser, additional = {}) => {
	const contactData = {
		email: currentUser?.attributes?.email,
		firstName: currentUser?.attributes?.profile?.firstName,
		lastName: currentUser?.attributes?.profile?.lastName,
		address: currentUser?.attributes?.profile?.publicData?.Address?.search,
		phoneNumber: currentUser?.attributes?.profile?.publicData?.phoneNumber,
		whatsappPhoneNumber: currentUser?.attributes?.profile?.publicData?.whatsappPhoneNumber,
		userType: currentUser?.attributes?.profile?.publicData?.userType,
		city: currentUser?.attributes?.profile?.publicData?.city,
		userId: currentUser.id.uuid,
		birthDate:currentUser?.attributes?.profile?.publicData?.birthDate,
		source: currentUser?.attributes?.profile?.publicData?.source,
		publicData: currentUser?.attributes?.profile?.publicData,
		...additional,
	};

	return contactData;
};
export const getParisDate = (date, isSubmission=false) => { 
	let dateObj = date ? new Date(date) : new Date();	
	if(!isNaN(dateObj)) {
		dateObj = new Date(dateObj.toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

		if(isSubmission) {
			return `${dateObj.getFullYear()}-${dateObj.getMonth() + 1}-${dateObj.getDate()} ${dateObj.getHours()} h :${dateObj.getMinutes()} m`;
		}
		return `${dateObj.getDate()}-${dateObj.getMonth() + 1}-${dateObj.getFullYear()}`;
	}
	return undefined;
};

export const prepareBrevoContactCreationData = params => {

  const createContact = {
    email: params?.email,
    updateEnabled: true,
  };

  const defaultInputs = {
    NOM: params?.firstName,
    PRENOM: params?.lastName,
    CITY: params?.city ?? '',
    PROFILE_COMPLETED: false,
    SERVICE_ADDED: false,
    PROFILE_VALIDATED: false,
    STUDENT_STAGE: 1,
    SUBMISSION_DATE: params?.publicData?.submissionDate ? params.publicData.submissionDate || getParisDate(undefined, true) : getParisDate(undefined, true),
    LIVEMODE: (appSettings.env === 'production'),
	BREVO_ID:0,
  };
  
  if (params?.userType && BASE_USER_TYPE_MAPPING[params?.userType]) {
    defaultInputs['BASE_USER_TYPE'] = BASE_USER_TYPE_MAPPING[params?.userType];
  }
  if (params?.source || params?.publicData?.source) {
    defaultInputs['SOURCE'] = params?.source || params?.publicData?.source;
  }
  if (params?.firstConnection ) {		
    defaultInputs['FIRST_CONNECTION'] = getParisDate(new Date());
  }
  const isOtherSourceUser = UserSource.canonical != defaultInputs['SOURCE'];  
  if(isOtherSourceUser && !defaultInputs['FIRST_CONNECTION']){
	defaultInputs['FIRST_CONNECTION'] = getParisDate(new Date());
}
  if (params?.password) {
    defaultInputs['PASSWORD'] = params.password;
  }
  createContact['attributes'] = defaultInputs;
  createContact['listIds'] = [6]; // NOTE: We are setting the list id to 6 in PHP.

  if (params?.address) {
    defaultInputs['ADDRESS'] = params.address;
    createContact.attributes = defaultInputs;
  }
  if(params?.publicData?.source === 2){
	defaultInputs['WHATSAPP'] = params.publicData.phoneNumber;
  }
	if (params?.publicData?.whatsappPhoneNumber) {
		defaultInputs['SMS'] = params.publicData.whatsappPhoneNumber;
		defaultInputs['WHATSAPP'] = params.publicData.whatsappPhoneNumber;
		createContact.attributes = defaultInputs;
	}
  if (params?.publicData?.phoneNumber) {
    defaultInputs['SMS'] = params.publicData.phoneNumber;
    createContact.attributes = defaultInputs;
  }
	createContact['userId'] = params?.userId;
	return createContact;
};

export const prepareBrevoContactUpdateData = params => {
	const updatesUserData = {
		NOM: params?.firstName,
		PRENOM: params?.lastName,
		ADDRESS: params?.address,
		BIRTH_DAY: params?.birthDate,
	};
	
	if(params?.publicData?.studentCurrentState) {
		updatesUserData['STUDENT_STAGE'] = studentProfileProgressToStageMapping[params.publicData.studentCurrentState] || 1;
	}
	if (params?.publicData?.firstConnection) {
    updatesUserData['FIRST_CONNECTION'] = getParisDate(params?.publicData?.firstConnection);
  }
	if(params?.publicData?.phoneNumber) {
		updatesUserData['SMS'] = params.publicData.phoneNumber;
	}
	if(params?.publicData?.whatsappPhoneNumber) {
		updatesUserData['WHATSAPP'] = params.publicData.whatsappPhoneNumber;
	}
	if(params?.publicData?.review_status) {
		updatesUserData['PROFILE_COMPLETED'] = true;
	}
	
	updatesUserData['PROFILE_VALIDATED'] = !!params?.publicData?.isUserVerified;
		
	updatesUserData['userId'] = params?.userId;
	return updatesUserData;
};

export const getRandomPassword = (length = 8) => {
	const alphabet = '!@#$abcdefghijklmnopqrstuvwxyz!@#$ABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$1234567890';
	const pass = [];
	const alphaLength = alphabet.length - 1;
	for(let i = 0; i < length; i++) {
		const n = Math.floor(Math.random() * alphaLength);
		pass.push(alphabet[n]);
	}
	return pass.join('');
};

export const getEncryptionText = (text, key) => {
	return CryptoJS.AES.encrypt(text, key).toString();
};
