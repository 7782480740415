import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_SERVICE_FEE, LINE_ITEM_SERVICE_FEE_CUSTOMER, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemServiceFeeCustomerMaybe = props => {
  const { lineItems, intl } = props;

  const serviceFeeLineItem = lineItems.find(
    item => item.code === LINE_ITEM_SERVICE_FEE_CUSTOMER && !item.reversal
  );

  return serviceFeeLineItem ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="OrderBreakdown.serviceFee" />
      </span>
      <span className={css.itemValue}>{formatMoney(intl, serviceFeeLineItem.lineTotal)}</span>
    </div>
  ) : null;
};

LineItemServiceFeeCustomerMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemServiceFeeCustomerMaybe;
