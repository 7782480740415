import React from 'react';

// Import config and utils
import {
  SCHEMA_TYPE_ENUM,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  SCHEMA_TYPE_LONG,
  SCHEMA_TYPE_BOOLEAN,
} from '../../util/types';
import { useIntl } from '../../util/reactIntl';
import { required, nonEmptyArray } from '../../util/validators';
// Import shared components
import { FieldCheckboxGroup, FieldSelect, FieldTextInput, FieldBoolean } from '..';
// Import modules from this directory
import css from './CustomExtendedDataField.module.css';

const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));
const getLabel = fieldConfig => fieldConfig?.saveConfig?.label || fieldConfig?.label;
const getkey = fieldConfig => fieldConfig?.saveConfig?.key || fieldConfig?.key;
const CustomFieldEnum = props => {
  const { name, fieldConfig, defaultRequiredMessage, intl } = props;
  const { enumOptions = [], saveConfig, key } = fieldConfig || {};
  const { placeholderMessage, isRequired, requiredMessage } = saveConfig || {};
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage ||
    intl.formatMessage({ id: 'CustomExtendedDataField.placeholderSingleSelect' });
  const filterOptions = createFilterOptions(enumOptions);

  const label = getLabel(fieldConfig);

  const GuardianAngelLabelLink = (
    <span className={css.privacyLink}>
      <a className={css.anchor} href='https://world-like-home.community/t/contrat-cadre-service-esa/3643'  target="_blank">  {intl.formatMessage({ id: 'CustomExtendedDataField.packs' })} </a>
    </span>
  );
  const la_formationLabel_Link = (
    <span className={css.privacyLink}>
      <a className={css.anchor} href='https://world-like-home.community/t/formation-des-esians-devenir-ange-gardien-pour-les-etudiants-en-mobilite-vers-la-france/5854'  target="_blank">  {intl.formatMessage({ id: 'CustomExtendedDataField.la_formation' })} </a>
    </span>
  );

  var displayLabel = <span> {intl.formatMessage({ id: `CustomExtendedDataField.${key}Label` })} {props?.required && <sup>*</sup>}</span>


  switch (key) {
    case 'GuardianAngel':
      displayLabel = <span> {intl.formatMessage({ id: 'CustomExtendedDataField.GuardianAngelLabel' }, { link: GuardianAngelLabelLink })} {props?.required && <sup>*</sup>}</span>
      break;
    case 'Certification_Guardian_Angel':
      displayLabel = <span> {intl.formatMessage({ id: 'CustomExtendedDataField.Certification_Guardian_AngelLabel' }, { link: la_formationLabel_Link })} {props?.required && <sup>*</sup>}</span>
      break;

  }
  return filterOptions ? (
    <FieldSelect className={css.customField} name={name} id={name} label={displayLabel} {...validateMaybe}>
      <option disabled value="">
        {placeholder}
      </option>
      {filterOptions.map(optionConfig => {
        const key = optionConfig.key;
        return (
          <option key={key} value={key}>
            {intl.formatMessage({ id: `CustomExtendedDataField.${key}Label` })}
          </option>
        );
      })}
    </FieldSelect>
  ) : null;
};

const CustomFieldMultiEnum = props => {
  const { name, fieldConfig, defaultRequiredMessage, intl } = props;
  const { enumOptions = [], saveConfig } = fieldConfig || {};
  const { isRequired, requiredMessage } = saveConfig || {};
  const label = getLabel(fieldConfig);
  const key = getkey(fieldConfig);
  const localizedLabel = intl.formatMessage({ id: `CustomExtendedDataField.${key}Label` });
  const validateMaybe = isRequired
    ? { validate: nonEmptyArray(requiredMessage || defaultRequiredMessage) }
    : {};
  var options = createFilterOptions(enumOptions)
  options.map(x => {
    x.label = intl.formatMessage({ id: `CustomExtendedDataField.${x.key}Label` });
  });

  return enumOptions ? (
    <FieldCheckboxGroup
      className={css.customField}
      id={name}
      name={name}
      label={localizedLabel}
      options={options}
      {...validateMaybe}
    />
  ) : null;
};

const CustomFieldText = props => {
  const { name, fieldConfig, defaultRequiredMessage, intl } = props;
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const key = getkey(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderText' });

  const localizedLabel = intl.formatMessage({ id: `CustomExtendedDataField.${key}Label` });

  if (key == 'Tell_us_esian' /*|| key == 'Biography_esian' || key == 'About_yourself_student'*/ || key == 'Tell_us_Study_student') {
    return (
      <FieldTextInput
        className={css.customField}
        id={name}
        name={name}
        type="textarea"
        label={localizedLabel}
        placeholder={placeholder}
        {...validateMaybe}
      />
    );
  }

  return (
    <FieldTextInput
      className={css.customField}
      id={name}
      name={name}
      type="text"
      label={localizedLabel}
      placeholder={placeholder}
      {...validateMaybe}
    />
  );
};

const CustomFieldLong = props => {
  const { name, fieldConfig, defaultRequiredMessage, intl } = props;
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderLong' });

  return (
    <FieldTextInput
      className={css.customField}
      id={name}
      name={name}
      type="number"
      step="1"
      parse={value => {
        const parsed = Number.parseInt(value, 10);
        return Number.isNaN(parsed) ? null : parsed;
      }}
      label={label}
      placeholder={placeholder}
      {...validateMaybe}
    />
  );
};

const CustomFieldBoolean = props => {
  const { name, fieldConfig, defaultRequiredMessage, intl } = props;
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderBoolean' });

  return (
    <FieldBoolean
      className={css.customField}
      id={name}
      name={name}
      label={label}
      placeholder={placeholder}
      {...validateMaybe}
    />
  );
};

/**
 * Return Final Form field for each configuration according to schema type.
 *
 * These custom extended data fields are for generating input fields from configuration defined
 * in marketplace-custom-config.js. Other panels in EditListingWizard might add more extended data
 * fields (e.g. shipping fee), but these are independently customizable.
 *
 * @param {Object} props should contain fieldConfig that defines schemaType, enumOptions?, and
 * saveConfig for the field.
 */
const CustomExtendedDataField = props => {
  const intl = useIntl();
  const { enumOptions = [], schemaType } = props?.fieldConfig || {};
  const renderFieldComponent = (FieldComponent, props) => <FieldComponent {...props} intl={intl} />;

  return schemaType === SCHEMA_TYPE_ENUM && enumOptions
    ? renderFieldComponent(CustomFieldEnum, props)
    : schemaType === SCHEMA_TYPE_MULTI_ENUM && enumOptions
      ? renderFieldComponent(CustomFieldMultiEnum, props)
      : schemaType === SCHEMA_TYPE_TEXT
        ? renderFieldComponent(CustomFieldText, props)
        : schemaType === SCHEMA_TYPE_LONG
          ? renderFieldComponent(CustomFieldLong, props)
          : schemaType === SCHEMA_TYPE_BOOLEAN
            ? renderFieldComponent(CustomFieldBoolean, props)
            : null;
};

export default CustomExtendedDataField;
